// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { Card } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import VDivider from '@shared/components/divider/VDivider';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';
import ConversationList from './components/conversation-list/ConversationList';
import MessagesList from './components/messages-list/MessagesList';
import { useSearchParams } from 'react-router-dom';

const ChatView: React.FC<{
  isMobile?: boolean;
  showMobileChatView: boolean;
  selectedConversation: EntityId | null;
  setSelectedConversation: (id: EntityId | null) => void;
}> = ({
  isMobile,
  showMobileChatView,
  selectedConversation,
  setSelectedConversation,
}) => {
  return (
    <>
      {!showMobileChatView && (
        <>
          <div className="w-full h-full sm:w-[33%]">
            <ConversationList
              selectedConversation={selectedConversation}
              selectConversation={setSelectedConversation}
              setSelectedConversation={setSelectedConversation}
            />
          </div>
          <VDivider />
        </>
      )}
      {(!isMobile || showMobileChatView) && (
        <div className="w-full h-full sm:w-[67%]">
          <MessagesList
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            showMobileChatView={showMobileChatView}
          />
        </div>
      )}
    </>
  );
};

const ChatViewContainer: React.FC<unknown> = () => {
  const [selectedConversation, setSelectedConversation] =
    React.useState<EntityId | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.getAll('chat');
    if (id && id.length) setSelectedConversation(id[0]);
    else setSelectedConversation(null);
  }, [searchParams, setSelectedConversation]);

  const handleRemoveParam = (paramKey) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete(paramKey); // Remove the specified key
    setSearchParams(newParams); // Update the search params
  };

  const openChat = (id?: string | null) => {
    if (id)
      setSearchParams({
        chat: id,
      });
    else handleRemoveParam('chat');
  };

  const isMobile = useIsMobile();
  const showMobileChatView = isMobile && selectedConversation !== null;

  const container = (children: React.ReactNode) => {
    if (!showMobileChatView) {
      return (
        <WithSideBar>
          <div className="flex justify-center w-full h-full pt-0 pb-0 sm:pt-5 sm:pb-4">
            <Card className="flex w-full h-full max-w-full sm:max-w-[80%] sm:min-w-[800px]">
              {children}
            </Card>
          </div>
        </WithSideBar>
      );
    } else {
      return (
        <div className="flex justify-center w-full pt-0 pb-0 sm:pt-5 sm:pb-4 h-[100vh]">
          {children}
        </div>
      );
    }
  };

  return container(
    <ChatView
      isMobile={isMobile}
      selectedConversation={selectedConversation}
      setSelectedConversation={openChat}
      showMobileChatView={showMobileChatView}
    />,
  );
};

export default ChatViewContainer;
