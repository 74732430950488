import React from 'react';
import moment from 'moment-timezone';
import { TextField } from '@mui/material';

const TimePicker: React.FC<{
  setTime;
  defaultValue?: {
    hour?: number;
    minute?: number;
    timeZone?: string;
  };
}> = ({ setTime, defaultValue }) => {
  const [hour, setHour] = React.useState<number>(
    moment().add(1, 'hour').hour(),
  );
  const [minute, setMinute] = React.useState<number>(moment().minute());
  const [timezone, setTimezone] = React.useState<string>(moment.tz.guess());

  React.useEffect(() => {
    setTime({ hour, minute, timezone });
  }, [hour, minute, setTime, timezone]);

  const renderPicker = (
    label: string,
    value,
    onChange,
    defaultValue?: any,
    options: { label: string; value: any }[] = [],
    className = '',
  ) =>
    defaultValue ? null : (
      <TextField
        id="outlined-select-currency-native"
        select
        className={className}
        label={label}
        value={value}
        disabled={defaultValue}
        onChange={(e) => onChange(e.target.value)}
        SelectProps={{
          native: true,
        }}
        variant="filled"
      >
        {options.map((option) => (
          <option key={`${label}-${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    );

  return (
    <div className="h4 text-on-secondary">
      Hour
      <div className="flex flex-wrap w-full mt-[14px]">
        {renderPicker(
          'Hour',
          hour,
          setHour,
          defaultValue?.hour,
          [...new Array(24)].map((_, i) => ({
            label: i.toString(),
            value: i,
          })),
          'flex-1 min-w-[100px]',
        )}
        {renderPicker(
          'Minute',
          minute,
          setMinute,
          defaultValue?.minute,
          [...new Array(60)].map((_, i) => ({
            label: i.toString(),
            value: i,
          })),
          'flex-1 min-w-[100px] !ml-3',
        )}
        {renderPicker(
          'Timezone',
          timezone,
          setTimezone,
          defaultValue?.timeZone,
          moment.tz.names().map((tz) => ({ label: tz, value: tz })),
          'flex-2 mb-12 sm:mb-0 !ml-3 mt-3 sm:mt-0',
        )}
      </div>
    </div>
  );
};

export default TimePicker;
