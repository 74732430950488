import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';
import { getAuthTokens } from '../shared/shared.thunk';

export const fetchProfile = createAsyncThunkWithAuth<{
  userId: string;
}>('profile/fetchProfile', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.EXTERNAL_PROFILE, {
          user: args.userId,
        }),
      )
      .then((response) => {
        resolve({
          ...response.data,
          community: args.community,
        });
      })
      .catch(reject);
  });
});

export const fetchProfilePicture = createAsyncThunkWithAuth<{
  targetId: number;
}>('profile/fetchProfilePicture', async (args, urlGenerator, dispatch) => {
  const tokens = await (dispatch(getAuthTokens()) as any).unwrap();

  const url = `${urlGenerator(ApiEndpoint.GET_PROFILE_PIC, {
    user: args.userId,
    targetId: args.targetId,
  })}?${new URLSearchParams({
    condition: args.community,
    device_id: args.deviceId,
  })}`;

  const response = await fetch(url, {
    method: 'GET',
    // redirect: 'error',
    headers: {
      accept: 'application/json',
      x_curatio_api_key: `Bearer ${tokens.appToken}`,
      x_curatio_authentication_token: tokens.authToken,
    },
  });

  return response.url;
});
