import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useDropDown from '../dropDown-menu/hooks/useDropDown';

type MultiFunctionButtonProps = {
  mainButton?: {
    label: string;
    disabled: boolean;
    action: () => void;
  };
  extraButtons?: {
    label: string;
    disabled?: boolean;
    Icon?: React.ReactNode;
  }[];
};

const MultiFunctionButton: React.FC<MultiFunctionButtonProps & any> = ({
  mainButton,
  extraButtons = [],
}) => {
  const { id, handleClose, handleOpen, open, anchorEl } = useDropDown();

  const handleOnExtraButtonsClick = (action) => () => {
    handleClose()();
    action();
  };

  const renderExtraActions = () => {
    if (extraButtons?.length === 0) return null;
    return (
      <Button
        disabled={extraButtons?.length === 0}
        variant="contained"
        className="rounded-full"
        classes={{
          root: '!w-[40px] !min-w-[40px] !p-0 !h-10 !bg-brand-primary !ml-1 ',
        }}
        onClick={handleOpen()}
      >
        <KeyboardArrowDownIcon />
      </Button>
    );
  };

  const renderMenu = () => {
    if (extraButtons?.length === 0) return null;
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            transform: 'translateX(-145px) translateY(5px)',
          },
        }}
      >
        {extraButtons.map((o, i) => (
          <MenuItem
            key={`${id}-${i}`}
            onClick={handleOnExtraButtonsClick(o.action)}
            classes={{
              root: 'h-11 !text-brand-primary',
            }}
          >
            {o.Icon}
            <span className="ml-3 text-on-secondary caption">{o.label}</span>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <div>
      <Button
        disabled={mainButton?.disabled}
        variant="contained"
        className="rounded-full"
        classes={{
          root: "!w-[115px] !h-10 !bg-brand-primary !font-medium !text-sm !font-['Roboto']",
        }}
        onClick={() => mainButton?.action()}
      >
        {mainButton?.label}
      </Button>
      {renderExtraActions()}
      {renderMenu()}
    </div>
  );
};

export default MultiFunctionButton;
