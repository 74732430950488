// @flow
import * as React from 'react';
import { TextareaAutosize } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

type SendBarProps = {
  onSend: (text: string) => void;
  placeholder?: string;
};

const SendBar: React.FC<SendBarProps> = ({ onSend, placeholder }) => {
  const [message, setMessage] = React.useState('');

  const handleSend = () => () => {
    if (message !== '') {
      onSend(message);
      setMessage('');
    }
  };

  return (
    <div
      className='flex items-center flex-1 h-full bg-secondary-background/[0.2] rounded-[100px] !pr-2 !pl-6 min-h-[46px] max-h-[75px] !pt-1 !pb-1 overflow-auto'>
      <TextareaAutosize
        // disableUnderline
        autoFocus

        // multiline
        value={message}
        placeholder={placeholder}
        onChange={(e) => setMessage(e.target.value)}
        // classes={{
        //   input: '!text-on-primary opacity-100 !font-[\'Roboto\'] max-h-[60px] !overflow-auto',
        //   root: 'max-h-[50px]',
        // }}
        className='flex-1 h-full overflow-auto flex-col-center  bg-transparent border-0'
      />
      <IconButton className='text-brand-primary' onClick={handleSend()}>
        <SendIcon />
      </IconButton>
    </div>
  );
};

export default SendBar;
