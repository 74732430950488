import React, { useCallback, useEffect, useState } from 'react';
import {
  deleteToken,
  getToken,
  isSupported as isFirebaseMessagingSupported,
  onMessage,
} from 'firebase/messaging';
import { useLocation } from 'react-router-dom';
import { messaging } from '@lib/configureFirebase';

type propsType = {
  cb: (token: string) => void;
  notificationHandler: (payload, location) => void;
  load: boolean;
  loggedIn: boolean;
};

export const CloudMessaging: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<propsType>>
> = ({ cb, load, notificationHandler, loggedIn }) => {
  const location = useLocation();
  const [tokenReceived, setTokenReceived] = useState(false);

  useEffect(() => {
    isFirebaseMessagingSupported().then((isSupported) => {
      if (isSupported) {
        onMessage(messaging, (payload) => {
          notificationHandler(payload, location);
        });
      }
    });
  }, [location, notificationHandler]);

  useEffect(() => {
    try {
      if (!loggedIn) deleteToken(messaging);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [loggedIn]);

  const getNotificationToken = useCallback(() => {
    setTokenReceived(true);
    isFirebaseMessagingSupported()
      .then((isSupported) => {
        console.log('isSupported', isSupported);
        if (isSupported) {
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
          })
            .then((token) => {
              cb(token);
            })
            .catch((e) => {
              setTokenReceived(false);
              // TODO log error
              console.log(e);
            });
        }
      })
      .catch((e) => {
        // TODO log error
        console.log(e);
      });
  }, [cb]);

  const [nuberOfTries, setNumberOfTries] = useState(0);
  useEffect(() => {
    if (nuberOfTries > 5) return;
    setNumberOfTries(nuberOfTries + 1);
    isFirebaseMessagingSupported().then((isSupported) => {
      if (!isSupported) return;
      if (!tokenReceived && loggedIn) {
        if (Notification.permission === 'granted') {
          getNotificationToken();
        } else
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') getNotificationToken();
          });
      }
    });
  }, [tokenReceived, getNotificationToken, loggedIn, nuberOfTries]);

  useEffect(() => {
    setTokenReceived(load);
  }, [load]);

  return null;
};

const CloudMessagingWrapper = ({ cb, load, notificationHandler, loggedIn }) => {
  return (
    <CloudMessaging
      loggedIn={loggedIn}
      load={load}
      cb={cb}
      notificationHandler={notificationHandler}
    />
  );
};

export default CloudMessagingWrapper;
