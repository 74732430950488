import { APIData } from '@data/shared/shared.thunk';
// import { store } from '../index';
import { ApiEndpoint } from './types';

const parseParams = (
  url: string,
  pathParams: Record<string, string | number> = {},
): string => {
  return Object.keys(pathParams).reduce((processedUrl, key) => {
    if (pathParams[key] === undefined) {
      return processedUrl;
    }
    return processedUrl.replace(`:${key}`, `${pathParams[key]}`);
  }, url);
};

// type CuratioServerApps = keyof typeof config.apps;

// export function isCuratioApp(value: string): value is CuratioServerApps {
//   return value in config.apps;
// }

// export const getApp = () => {
//   const app = localStorage.getItem('appSelected');
//   if (app) {
//     return config.apps[app];
//   }
//   return null;
// };

/**
 * Generate API Route
 *
 * @param endpoint
 * @param params
 *
 */
const generateApiRoute = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoint: ApiEndpoint,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params?: Record<string, string | number>,
): string => {
  // const parsed = parseParams(endpoint, params);
  // const app = store.getState().app.selectedApp;
  //
  // if (!app) {
  //   window.location.href = '/app-selection';
  // }
  // return `${app?.apiUrl}/${parsed}`;
  return '';
};

const generateApiRoute2 = (
  endpoint: ApiEndpoint,
  params:
    | (APIData & Record<string, string | number>)
    | Record<string, string | number>,
): string => {
  const parsed = parseParams(endpoint, params);
  if (!params.baseUrl) {
    window.location.href = '/app-selection';
  }

  const requiredParams = {
    community: 'condition',
    deviceId: 'device_id',
    notificationType: 'notification_type',
  };

  return Object.keys(requiredParams)
    .reduce((url, key) => {
      if (params[key] === undefined) return url;
      return `${url}${requiredParams[key]}=${params[key]}&`;
    }, `${params.baseUrl}/${parsed}?`)
    .slice(0, -1);
};

/**
 * Generate Express API Route
 *
 * @param endpoint
 */
// export const generateExpressRoute = (endpoint: ApiEndpoint, params?: any) => {
//   const parsed = parseParams(endpoint, params);
//   return `${config.expressApiUrl}/${parsed}`;                                                                                                                                  nh nh nh
// };

export default {
  parseParams,
  generateApiRoute,
  generateApiRoute2,
};
