import { useSelector } from 'react-redux';
import moment from 'moment';
import { ChatSelector } from '@data/chat/Chat.selector';
import useProfilePicture from '@hooks/use-profile-pic/useProfilePicture';
import useAppDispatch from '@data/useAppDispatch';
import { useState } from 'react';
import { fetchProfile } from '@data/profiles/Profile.thunk';

const useConversation = (id) => {
  const conversation = useSelector((state: any) =>
    ChatSelector.conversations.selectById(state.chat.conversations, id),
  );

  const time = moment(conversation?.latestMessageTime).format('DD MMM');

  const avatar = useProfilePicture(conversation?.userId);

  const dispatch = useAppDispatch();

  const [user, setUser] = useState<any>(null);

  if (!conversation && id) {
    // @TODO fix this: multiple calls to fetchProfile
    dispatch(fetchProfile({ userId: id })).then((res) => {
      setUser({
        nickname: res.profile.nick_name,
        avatar: res.profile.image_url,
      });
    });
  }

  return {
    user: id && {
      name: user?.nickname || conversation?.nickname,
      avatar,
      isOnline: conversation?.isOnline,
    },
    message: conversation?.latestMessage,
    time,
    unreadCount: conversation?.unreadMessageCount || 0,
  };
};

export default useConversation;
