import { Content, ContentTypes } from '@data/contents/Contents.model';
import React from 'react';
import { Card, LinearProgress } from '@mui/material';
import useContent from '@view/content-management-system/hooks/useContent';
import CircleIcon from '@mui/icons-material/Circle';
import ShowIcon from '@mui/icons-material/Visibility';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import LikedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { ReactComponent as KarmaSvg } from '@assets/icons/karma.svg';
import Endpoints from '@view/routes/endpoints';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  bookmarkContent,
  markContentAsHelpful,
  startSeries,
  unbookmarkContent,
} from '@data/contents/Contents.thunk';
import useAppDispatch from '@data/useAppDispatch';
import PostShareMenu from '@shared/components/post/components/post-intraction-bar/PostShareMenu';
import MakePostDialogProvider from '@shared/components/make-post/MakePostDialogProvider';
import { ShareInChatDialog } from '@shared/components/shared-post/ShareInChatDialog';

const ContentCard: React.FC<{
  content: Content;
  index: number;
}> = ({ content, index }) => {
  const navigate = useNavigate();

  const {
    id,
    contentMark,
    title,
    contentType,
    cover,
    readTime,
    views,
    likes,
    karmaPoints,
    noOfSteps,
    noOfCompletedSteps,
    isStarted,
    bookmarked,
  } = useContent(content);

  const [shareAnchorEl, setShareAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleClose = React.useCallback(
    (setAnchorEl) => () => {
      setAnchorEl(null);
    },
    [],
  );

  const [openDialog, setDialogOpen] = React.useState(false);
  const openResharePostDialog = React.useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleOpen = React.useCallback(
    (setAnchorEl, anchorEl) => (event: React.MouseEvent<HTMLElement>) => {
      if (anchorEl) handleClose(setAnchorEl)();
      else setAnchorEl(event.currentTarget);
    },
    [handleClose],
  );

  const renderContentType = React.useCallback(() => {
    if (!contentType) return null;
    return (
      <div className="flex items-center w-full justify-left">
        {contentType.icon('w-[14px] h-[14px]')}
        <span className="ml-2 uppercase caption  text-[#4D565F]">
          {contentType.label}
        </span>
      </div>
    );
  }, [contentType]);

  const renderInfo = React.useCallback(() => {
    if (!contentType) return null;
    if (contentType?.value === ContentTypes.ARTICLE) return readTime;
    if (contentType?.value === ContentTypes.SERIES) return `${noOfSteps} steps`;
    return null;
  }, [contentType, readTime, noOfSteps]);

  const renderKarmaPoints = React.useCallback(() => {
    if (!contentType) return null;
    const completed = noOfCompletedSteps === noOfSteps;
    if (contentType?.value === ContentTypes.ARTICLE) return null;
    return (
      <div
        className={`absolute flex items-center h-8 p-1 w-[73px] bg-white/90 top-2 right-2 justify-evenly caption rounded-[6px]  text-on-primary
        ${completed && '!bg-success text-white'}`}
      >
        <KarmaSvg className="w-5" />
        <span className="ml-2">+{karmaPoints}</span>
      </div>
    );
  }, [contentType, karmaPoints, noOfCompletedSteps, noOfSteps]);

  const dispatch = useAppDispatch();

  const start = React.useCallback(() => {
    dispatch(startSeries({ id })).then(() => {
      navigate(Endpoints.DISCOVER_VIEW.replace(':contentId', id));
    });
  }, [dispatch, id, navigate]);

  const renderSeriesStepsIndicator = React.useCallback(() => {
    if (!contentType) return null;
    if (contentType?.value !== ContentTypes.SERIES) return null;
    if (isStarted) {
      return (
        <div className="absolute bottom-0 w-full h-fit bg-white/80">
          {noOfCompletedSteps < noOfSteps && (
            <p className="mt-2 mb-2 ml-4 caption text-on-secondary">
              {noOfCompletedSteps} of {noOfSteps} steps completed
            </p>
          )}
          <LinearProgress
            className="w-full h-2"
            variant="determinate"
            value={(noOfCompletedSteps / noOfSteps) * 100}
            classes={{
              colorPrimary: 'bg-secondary-background',
              bar: 'bg-success',
            }}
          />
        </div>
      );
    }
    return (
      <div className="absolute w-full h-[30px] bg-white/80 top-16">
        <div className="flex items-center justify-between w-full h-full p-3">
          <span className="caption text-secondary-black">
            {noOfSteps} steps
          </span>{' '}
          <span className="cursor-pointer button text-tertiary" onClick={start}>
            START SERIES
          </span>
        </div>
      </div>
    );
  }, [contentType, isStarted, noOfSteps, start, noOfCompletedSteps]);

  const markAsHelpful = React.useCallback(() => {
    dispatch(markContentAsHelpful(content.id));
  }, [content.id, dispatch]);

  const toggleBookmark = React.useCallback(() => {
    if (!bookmarked) {
      dispatch(bookmarkContent({ id }));
    } else {
      dispatch(unbookmarkContent({ id }));
    }
  }, [bookmarked, dispatch, id]);

  const [openShareInChatDialog, setOpenShareInChatDialog] =
    React.useState(false);

  const handleShareOnChat = () => {
    setOpenShareInChatDialog(true);
    handleClose(setShareAnchorEl);
  };

  return (
    <Card
      className={`relative flex flex-col mb-4 mr-[13px] w-[273px] h-[294px] ${
        index % 3 === 0 ? 'mr-4.5' : ''
      }`}
    >
      <ShareInChatDialog
        open={openShareInChatDialog}
        onClose={() => setOpenShareInChatDialog(false)}
        id={content.id}
        type="discover"
      />
      <MakePostDialogProvider
        open={{
          mode: openDialog ? 'reshare' : 'close',
          contentId: content.id,
        }}
        closeDialog={() => {
          setDialogOpen(false);
        }}
      />
      <div className="relative w-full">
        {renderKarmaPoints()}
        <img src={cover} alt={title} className="object-cover w-full h-[94px]" />
        {renderSeriesStepsIndicator()}
      </div>
      <div
        className="flex-1 w-full p-5 pb-0"
        onClick={() => {
          navigate(Endpoints.DISCOVER_VIEW.replace(':contentId', content.id));
        }}
      >
        {renderContentType()}
        <h5 className="mt-2 mb-2 h5">{title}</h5>
        <div className="caption text-on-secondary">
          {renderInfo()}
          <CircleIcon className="ml-2 mr-2 w-1.5 h-1.5 !text-on-secondary" />
          <ShowIcon className="w-4 mr-2 !text-on-secondary" />
          {views} views
        </div>
      </div>
      <div className="relative w-full p-4">
        <IconButton onClick={toggleBookmark} className="-mb-2 -ml-2">
          {!bookmarked ? (
            <BookmarkBorderIcon className="w-[22px] h-[22px] text-on-secondary" />
          ) : (
            <BookmarkOutlinedIcon className="w-[22px] h-[22px] text-brand-primary" />
          )}
        </IconButton>
        <IconButton
          onClick={handleOpen(setShareAnchorEl, shareAnchorEl)}
          className="-mb-2"
        >
          <ReplyIcon
            className="!fill-on-secondary"
            style={{
              transform: 'scale(-1, 1)',
            }}
          />
          <PostShareMenu
            anchorEl={shareAnchorEl}
            handleClose={handleClose(setShareAnchorEl)}
            openResharePostDialog={openResharePostDialog}
            handleShareOnChat={handleShareOnChat}
          />
        </IconButton>

        <div className="absolute flex items-center right-5 bottom-[10px]">
          <IconButton
            onClick={contentMark !== 'helpful' ? markAsHelpful : undefined}
          >
            <LikedIcon
              className={`w-[20px] h-[20px] ${
                contentMark === 'helpful'
                  ? 'text-brand-primary'
                  : 'text-on-secondary'
              }`}
            />
          </IconButton>
          <h5 className="ml-2 h5 text-on-secondary">{likes}</h5>
        </div>
      </div>
    </Card>
  );
};

export default ContentCard;
