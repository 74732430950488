import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT,
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const requestFCMPermission = async () => {
  try {
    // Request user permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get FCM token (the public VAPID key is required for web push)
      const token = await getToken(messaging, {
        vapidKey:
          'BETf6rOvG8g7fZu-zJXP5OvTUEq5K_S5Lyk41dO2u7kDD9gDR7RoDe_Y9IitmhpjWD753a2SEbCHtcGLi8NkKeg',
      });

      if (token) {
        console.log('FCM Token:', token);
        // Send the token to your server or store it as needed
      } else {
        console.log('No registration token available.');
      }
    } else {
      console.log('Notification permission not granted.');
    }
  } catch (error) {
    console.error('Error while requesting permission or getting token:', error);
  }
};
