import { createSelector } from '@reduxjs/toolkit';
import { chatAdapter } from './Chat.reducer';

export const ChatSelector = {
  conversations: {
    ...chatAdapter.conversations.getSelectors(),
    selectFilteredConversations: createSelector(
      [
        (state) =>
          chatAdapter.conversations
            .getSelectors()
            .selectAll(state.chat.conversations),
        (_, filter: { search?: string; selectedTab: string }) => filter,
      ],
      (conversations, filter) => {
        return conversations
          .filter((conversation) => {
            return (
              !(
                filter.selectedTab === 'Unread' &&
                conversation.unreadMessageCount === 0
              ) &&
              !(
                filter.selectedTab === 'Read' &&
                conversation.unreadMessageCount > 0
              ) &&
              (filter.search === undefined ||
                conversation.nickname
                  .toLowerCase()
                  .includes(filter.search.toLowerCase()))
            );
          })
          .map((conversation) => {
            return conversation.userId;
          });
      },
    ),
  },
  messages: {
    ...chatAdapter.message.getSelectors(),
  },
};
