// @flow
import * as React from 'react';
import { Dialog, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import ImageCropUpload from '../Image-crop-upload/ImageCropUpload';

type CreateGroupDialogProps = {
  open: boolean;
  mode: 'create' | 'edit';
  setCover: () => (cover?: string) => void;
  onCancel: () => () => void;
  onSubmit: () => () => void;
  setTitle: () => (e) => void;
  setDescription: () => (e) => void;
  loading?: boolean;
  isValid?: boolean;
  cover?: string;
  title?: string;
  description?: string;
};

const CreateGroupDialog: React.FC<CreateGroupDialogProps> = (props) => {
  const {
    open,
    mode = 'create',
    setCover,
    onCancel,
    onSubmit,
    setTitle,
    setDescription,
    loading,
    isValid,
    cover,
    title,
    description,
  } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={Boolean(open)}
      classes={{
        paper:
          'w-full max-w-full sm:max-w-[540px] h-auto pt-6 pb-4 relative !m-1 ',
      }}
    >
      <h1 className="pl-5 h3 text-on-primary">
        {mode === 'create' ? t(TextKeys.CreateGroup) : t(TextKeys.EditGroup)}
      </h1>
      <p className="pt-3 pb-3 pl-5 pr-5 body1">
        {t(TextKeys.CreateGroupDescription)}
      </p>
      <IconButton onClick={onCancel()} className="absolute top-4 right-5">
        <ClearIcon />
      </IconButton>
      <ImageCropUpload
        setImage={setCover()}
        height={160}
        defaultImage={cover}
      />
      <div className="w-full pt-4 pb-4 pl-5 pr-5 flex-col-center">
        <TextField
          className="w-full mb-7"
          label={t(TextKeys.GroupTitle)}
          variant="outlined"
          value={title}
          onChange={setTitle()}
        />
        <TextField
          value={description}
          className="w-full"
          label={t(TextKeys.GroupDescription)}
          multiline
          onChange={setDescription()}
          rows={4}
        />
        <div className="mt-4">
          <Button
            className="!ml-2 !mr-2 w-[130px] h-[40px]"
            variant="outlined"
            color="primary"
            onClick={onCancel()}
            disabled={loading}
          >
            {t(TextKeys.Cancel)}
          </Button>
          <LoadingButton
            className="!ml-2 !mr-2 w-[130px] h-[40px]"
            variant="contained"
            color="primary"
            onClick={onSubmit()}
            disabled={!isValid}
            loading={loading}
          >
            {t(TextKeys.Submit)}
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
};

CreateGroupDialog.defaultProps = {
  loading: false,
  isValid: false,
  cover: '',
  title: '',
  description: '',
};

export default CreateGroupDialog;
