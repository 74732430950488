import React from 'react';
import { Button, Divider } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import Avatar from '../../../avatar';

type props = {
  avatar?: string;
  buttons?: {
    Icon: React.ReactNode;
    label: string;
    onClick?: () => void;
  }[];
  onClick?: (type?: 'photo' | 'video') => void;
};

const MakePostContainer: React.FC<props> = ({
                                              avatar,
                                              buttons = [
                                                {
                                                  Icon: <CameraAltIcon
                                                    className='fill-black' />,
                                                  label: 'Photo',
                                                  key: 'photo',
                                                },
                                                {
                                                  Icon: <VideocamIcon
                                                    className='fill-black' />,
                                                  label: 'Video',
                                                  key: 'video',
                                                },
                                              ],
                                              onClick,
                                            }) => {
  const { t } = useTranslation();

  const handleClick = (type?: 'photo' | 'video') => () => {
    if (onClick) onClick(type);
  };

  const renderButtons = ({ Icon, label, key }, className = '') => (
    <Button
      startIcon={Icon}
      className={`${className}`}
      key={`${label}-button`}
      onClick={handleClick(key)}
    >
      <span className='font-normal capitalize caption text-on-secondary'>
        {label}
      </span>
    </Button>
  );


  return (
    <div className='w-full mt-5 mb-5 bg-white h-[122px] rounded-md'>
      <div
        className='flex items-center w-full cursor-pointer h-[71px]'
        onClick={handleClick()}
      >
        <Avatar src={avatar} className='ml-[23px] !w-[50px] !h-[50px]' />
        <span className='opacity-50 h5 text-on-primary ml-7'>
          {t(TextKeys.WhatIsInYourMind)}
        </span>
      </div>
      <Divider />
      <div className='flex items-center w-full h-[51px]'>
        {buttons.map((b, i) =>
          renderButtons(b, `ml-[${i > 0 ? '83px' : '23px'}] caption`),
        )}
      </div>
    </div>
  );
};

export default MakePostContainer;
