// @flow
import Avatar from '@shared/components/avatar';
import * as React from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { MyCircleSelector } from '@data/my-circle/MyCircle.selector';
import { Friend } from '@data/my-circle/MyCircle.model';
import { useNavigate } from 'react-router-dom';
import Endpoints from '@view/routes/endpoints';

type MyFriendItemProps = {
  friendId: number | string;
};

const MyFriendItem: React.FC<MyFriendItemProps> = ({ friendId }) => {
  const { t } = useTranslation();

  const friend: Friend | undefined = useSelector((state: any) =>
    MyCircleSelector.selectById(state, friendId),
  );
  const id = friend?.id;
  const avatar = friend?.info?.avatar || '';
  const nickname = friend?.info?.nickname || '';
  const isOnline = friend?.isOnline || false;
  const country = friend?.info?.country || '';
  const age = friend?.info?.age.from
    ? `${friend?.info?.age.from} - ${friend?.info?.age.to} ${t(
        TextKeys.YearsOld,
      )}`
    : '';

  const navigate = useNavigate();

  const openChat = (id?: number) => () => {
    navigate(Endpoints.CHAT + `?chat=${id}`);
  };

  const openProfile = (id?: number) => () => {
    navigate(Endpoints.PROFILE_ID.replace(':userId', `${id}`));
  };

  return (
    <div className="flex justify-between w-full pl-5 pr-3 mt-3 mb-3 h-[64px]">
      <div className="flex h-full" onClick={openProfile(id)}>
        <Avatar src={avatar} withOnlineSign online={isOnline} />
        <div className="flex flex-col justify-center h-full ml-5 body2 text-on-secondary">
          <h6 className="h6 text-on-primary">{nickname}</h6>
          <p>{country}</p>
          <p>{age}</p>
        </div>
      </div>
      <IconButton
        className="flex items-center justify-center p-2 rounded-none text-on-secondary"
        onClick={openChat(id)}
      >
        <ChatIcon className="h-[30px] w-[30px]" />
        <span className="ml-5 subtitle2">{t(TextKeys.SayHi)}</span>
      </IconButton>
    </div>
  );
};

export default MyFriendItem;
