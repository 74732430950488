import React from 'react';
import { Card } from '@mui/material';
import { ReactComponent as ReshareComponent } from '@assets/icons/reshare.svg';
import { useNavigate } from 'react-router-dom';
import { ContentTypes } from '@data/contents/Contents.model';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import { getContentTypeView } from '../../../../logic/CMS/content.logic';

export const SharedContentDetails: React.FC<{
  content: {
    id: number;
    title: string;
    cover: string;
    type: ContentTypes;
  };
  clickable?: boolean;
  time?: string;
}> = ({ content, clickable, time }) => {
  const reshareMarker = () => {
    return (
      <div
        className='absolute flex items-center justify-center w-10 h-10 rounded-full bg-on-primary opacity-80 left-2'
        style={{
          top: '-20px',
        }}
      >
        <ReshareComponent className='w-3 h-3' />
      </div>
    );
  };

  const renderAttachment = () => {
    return (
      <img
        src={content.cover}
        alt={`attachment-${content.id}`}
        className='object-cover w-20 h-16 mr-2 rounded-lg'
      />
    );
  };

  const navigate = useNavigate();

  const { icon } = getContentTypeView(content.type);

  return (
    <div
      className={`w-full h-22 mt-8 relative ${
        clickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (clickable) {
          navigate(endpointIDReplacer(Endpoints.DISCOVER_VIEW, content?.id));
        }
      }}
    >
      {reshareMarker()}
      <Card className='w-full h-full pt-3 pb-3 pl-2 pr-2 rounded-lg bg-accent'>
        <div className='flex items-start'>
          {renderAttachment()}
          <div className='flex-col'>
            <div className='flex items-center justify-left'>
              {icon('w-4 h-4')}
              <p className='ml-2 text-secondary-black h6'>{content?.type}</p>
            </div>
            <p className='mt-2 text-on-background h6 ellipsis2'>
              {content?.title}
            </p>
          </div>
        </div>
        {
          time && (
            <div
              className='w-full text-right mt-3 caption text-on-primary opacity-50'
            >
              {time}
            </div>
          )
        }
      </Card>
    </div>
  );
};
